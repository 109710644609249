<template>
  <div id="orders" class="grid">
    <div class="pg-header">
      <h2>Arbetsordrar</h2>
    </div>
    <div id="alertBox" role="alert">
      <transition-group name="fade">
        <div v-show="showSuccessAlertBox" key="ok" class="alert alert-success">
          <strong>{{ message }}</strong>
        </div>
        <div v-show="showDangerAlertBox" key="nok" class="alert alert-danger">
          <strong>{{ message }}</strong>
        </div>
      </transition-group>
    </div>
    <div class="pg-table">
      <div v-if="errorMessage === '' && !loading">
        <div class="pg-table-toolbar">
          <div class="pg-search-input">
            <input v-model="search" type="text" class="searchInput" placeholder="Sök" />
          </div>
          <div class="pg-table-toolbar-action-buttons">
            <div v-if="isWorker || isSeller || isAdmin">
              <button class="btn-primary" onmousedown="event.preventDefault()" @click="reload">Uppdatera</button>
            </div>
            <div v-if="isAdmin">
              <button class="btn-primary" @click="addOrder">Lägg till</button>
            </div>
          </div>
        </div>
        <div>
          <table id="tblOrders" class="content-table">
            <thead>
              <tr>
                <th></th>
                <th>Ordernr.</th>
                <th>Kundnamn</th>
                <th>Prio</th>
                <th>Leveransdatum</th>
                <th>Nytt lev.datum</th>
                <th>Färg</th>
                <th>Antal pall</th>
                <th>Status</th>
                <th></th>
                <th v-if="isAdmin || isSeller"></th>
                <th v-if="isAdmin"></th>
              </tr>
            </thead>
            <tbody v-if="!loading && workorders.length > 0">
              <tr
                v-for="order in filtredOrders"
                :key="order.order_id"
                :class="{
                  'status-started': order.order_status_id == 2,
                  'status-doneforpainting': order.order_status_id == 3,
                  'status-donefordelivery': order.order_status_id == 4,
                  'status-delivered': order.order_status_id == 5,
                  'status-partlydelivered': order.order_status_id == 6,
                  'status-suspended': order.order_status_id == 7
                }">
                <td>
                  <div v-if="(order.changed_delivery_date.length < 1 && order.delivery_date < todayDate) || (order.changed_delivery_date.length > 0 && order.changed_delivery_date < todayDate)">
                    <img src="../../assets/exclamation-triangle-solid.svg" class="svg-icon-no-pointer svg-icon-alert" />
                  </div>
                </td>
                <td v-if="order.order_files.length > 0">
                  <a href="#" class="ordernumber-link" @click.prevent="clickPDF(order)" title="Visa dokument"> {{ order.order_number }}</a>
                </td>
                <td v-else>{{ order.order_number }}</td>
                <td>{{ order.customer_name }}</td>
                <td v-if="order.prio_number > 0">
                  {{ order.prio_number }}
                </td>
                <td v-else></td>
                <td
                  :class="{
                    'status-lateorder': order.changed_delivery_date.length < 1 && order.delivery_date < todayDate
                  }">
                  {{ order.delivery_date }}
                </td>
                <td
                  :class="{
                    'status-lateorder': order.changed_delivery_date.length > 0 && order.changed_delivery_date < todayDate
                  }">
                  {{ order.changed_delivery_date }}
                </td>
                <td>{{ order.color }}</td>
                <td>{{ order.palette_amount }}</td>
                <td v-if="isWorker || isAdmin">
                  <select v-model="order.order_status_id" class="pg-input-select" @change="saveOrder(order)">
                    <option
                      v-for="order_status in myWorkOrderStatus"
                      :key="order_status.order_status_id"
                      :value="order_status.order_status_id"
                      :selected="order_status.order_status_id === order.order_status_id">
                      {{ order_status.order_status_name }}
                    </option>
                  </select>
                </td>
                <td v-if="isSeller">{{ order.order_status_name }}</td>
                <td @click="clickComment(order.order_id)">
                  <img
                    src="../../assets/comment-regular.svg"
                    class="svg-icon"
                    :class="{
                      'svg-icon-available': order.comments.length > 0,
                      'svg-icon-not-available': order.comments.length < 1
                    }"
                    title="Kommentera order" />
                </td>
                <td v-if="isAdmin || isSeller" @click="clickSendEMail(order)">
                  <img
                    src="../../assets/envelope-regular.svg"
                    class="svg-icon"
                    :class="{
                      'svg-icon-available': order.email_address.length > 0,
                      'svg-icon-not-available': order.email_address.length < 1
                    }"
                    title="Skicka e-post" />
                </td>
                <td v-if="isAdmin" @click="editOrder(order.order_id)">
                  <img src="../../assets/edit-regular.svg" class="svg-icon svg-icon-edit" title="Editera order" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
    <sendemail-modal v-show="isSendEmailModalVisible" :order="myOrder" :force="forceUpdateModalCounter" @close="closeSendEmailModal" @send="send">
      <template #header>
        <template> Skicka orderinformation </template>
      </template>
    </sendemail-modal>
    <pdf-modal v-show="isFilesModalVisible" :order="myOrder" :force="forceUpdateModalCounter" @close="closeFilesModal"></pdf-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';
import Spinner from '@/components/utils/Spinner';
import PGSendEMailModal from '@/components/PG-SendEmailModal.vue';
import PGPDFModal from '@/components/PG-PDFModal.vue';

export default {
  name: 'WorkOrders',
  components: {
    Spinner,
    'sendemail-modal': PGSendEMailModal,
    'pdf-modal': PGPDFModal
  },
  props: {
    addUpdateOK: {
      type: Boolean,
      default: false
    },
    hasAddUpdate: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      isSendEmailModalVisible: false,
      isFilesModalVisible: false,
      forceUpdateModalCounter: 0,
      workorders: [],
      myWorkOrderStatus: [],
      myOrder: {
        order_id: 0,
        order_number: '',
        prio_number: 0,
        customer_name: '',
        delivery_date: new Date(),
        changed_delivery_date: null,
        color: '',
        palette_amount: 1,
        order_status_id: 1,
        comments: '',
        delivery_id: '',
        order_link: '',
        email_address: '',
        email_sent_date: null,
        order_files: []
      },
      myOrderFiles: [],
      search: '',
      message: '',
      showSuccessAlertBox: false,
      showDangerAlertBox: false
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    },
    todayDate: function () {
      return format(new Date(), 'yyyy-MM-dd');
    },
    filtredOrders() {
      const trimmedSearch = this.search.toLowerCase().trim();
      return this.workorders.filter((order) => {
        return (
          order.order_number.toLowerCase().match(trimmedSearch) ||
          order.customer_name.toLowerCase().match(trimmedSearch) ||
          order.delivery_date.toLowerCase().match(trimmedSearch) ||
          order.changed_delivery_date.toLowerCase().match(trimmedSearch) ||
          order.color.toLowerCase().match(trimmedSearch) ||
          order.delivery_id.toLowerCase().match(trimmedSearch) ||
          order.order_status_name.toLowerCase().match(trimmedSearch)
        );
      });
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchMyWorkOrderStatus();
    await this.fetchMyOrders();
    this.loading = false;
  },
  created() {
    if (this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Arbetsordern är nu sparad';
      }
      if (this.action == 'DELETE') {
        this.message = 'Arbetsordern är nu raderad';
      }
      setTimeout(() => (this.showSuccessAlertBox = true), 0);
      setTimeout(() => (this.showSuccessAlertBox = false), 3000);
    }
    if (!this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Arbetsordern kunde inte sparas';
      }
      if (this.action == 'DELETE') {
        this.message = 'Arbetsordern kunde inte raderas';
      }
      setTimeout(() => (this.showDangerAlertBox = true), 0);
      setTimeout(() => (this.showDangerAlertBox = false), 3000);
    }
  },
  updated() {},
  beforeUpdate() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      fetchWorkOrders: 'orders/fetchWorkOrders',
      fetchWorkOrderStatus: 'misc/fetchWorkOrderStatus',
      fetchOrderFiles: 'orders-utils/fetchOrderFiles',
      updateOrder: 'orders/updateOrder',
      sendEmail: 'orders-utils/sendEmail'
    }),
    closeSendEmailModal() {
      this.showSendEmailModal = false;
      this.isSendEmailModalVisible = false;
    },
    closeFilesModal() {
      this.showFilesModal = false;
      this.isFilesModalVisible = false;
    },
    clickPDF(order) {
      this.myOrder = order;
      this.forceUpdateModalCounter = this.forceUpdateModalCounter + 1;
      this.isFilesModalVisible = true;
    },

    send: function (email_address) {
      this.closeSendEmailModal();
      this.myOrder.email_address = email_address;
      this.sendMyEmail();
    },
    clickComment: function (order_id) {
      this.$router.replace({
        name: 'WorkOrderComments',
        params: {
          orderId: order_id
        }
      });
    },
    clickSendEMail: function (order) {
      this.forceUpdateModalCounter = this.forceUpdateModalCounter + 1;
      this.myOrder = order;
      this.isSendEmailModalVisible = true;
    },
    editOrder: function (order_id) {
      this.$router.replace({
        name: 'Order',
        params: {
          orderId: order_id,
          originView: 'WorkOrders'
        }
      });
    },
    addOrder() {
      this.$router.replace({
        name: 'Order',
        params: {
          orderId: 0,
          originView: 'WorkOrders'
        }
      });
    },
    saveOrder: function (order) {
      this.myOrder.order_id = order.order_id;
      this.myOrder.order_number = order.order_number;
      this.myOrder.prio_number = order.prio_number;
      this.myOrder.customer_name = order.customer_name;
      this.myOrder.delivery_date = order.delivery_date;
      this.myOrder.changed_delivery_date = order.changed_delivery_date;
      this.myOrder.color = order.color;
      this.myOrder.palette_amount = order.palette_amount;
      this.myOrder.comments = order.comments;
      this.myOrder.delivery_id = order.delivery_id;
      this.myOrder.order_status_id = order.order_status_id;

      this.saveMyOrder();
    },
    async reload() {
      await this.fetchMyOrders();
    },
    async fetchMyOrders() {
      this.errorMessage = '';
      try {
        this.workorders = [];
        this.workorders = await this.fetchWorkOrders();
      } catch (error) {
        // console.log("fetchMyOrders ERROR");
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      }
    },
    async fetchMyWorkOrderStatus() {
      this.errorMessage = '';
      try {
        this.myWorkOrderStatus = [];

        this.myWorkOrderStatus = await this.fetchWorkOrderStatus();
      } catch (error) {
        //console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async fetchMyOrderFiles() {
      try {
        this.myOrderFiles = [];
        let data = {
          order_id: this.orderId
        };
        this.myOrderFiles = await this.fetchOrderFiles(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },

    async saveMyOrder() {
      let response = null;

      try {
        if (this.myOrder.changed_delivery_date == '') {
          this.myOrder.changed_delivery_date = '1970-01-01';
        }

        let data = {
          order_id: this.myOrder.order_id,
          order_number: this.myOrder.order_number,
          prio_number: this.myOrder.prio_number,
          customer_name: this.myOrder.customer_name,
          delivery_date: format(new Date(this.myOrder.delivery_date), 'yyyy-MM-dd'),
          changed_delivery_date: format(new Date(this.myOrder.changed_delivery_date), 'yyyy-MM-dd'),
          color: this.myOrder.color,
          palette_amount: this.myOrder.palette_amount,
          comments: this.myOrder.comments,
          delivery_id: this.myOrder.delivery_id,
          order_status_id: this.myOrder.order_status_id
        };

        response = await this.updateOrder(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;
        if (response && response.success == 1) {
          addUpdateOK = true;
        }
      }
    },
    async sendMyEmail() {
      this.errorMessage = '';
      try {
        let data = {
          order_id: this.myOrder.order_id,
          order_number: this.myOrder.order_number,
          order_link: this.myOrder.order_link,
          email_address: this.myOrder.email_address,
          email_sent_date: this.myOrder.order_id
        };
        const response = await this.sendEmail(data);

        this.message = 'E-post är nu skickad';
        setTimeout(() => (this.showSuccessAlertBox = true), 0);
        setTimeout(() => (this.showSuccessAlertBox = false), 3000);
        this.reload();
      } catch (error) {
        //console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pg-table {
  grid-column: 1/13;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
#alertBox {
  grid-column: 1/13;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (max-width: 1690px) {
  .pg-table {
    grid-column: 1/13;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  #alertBox {
    grid-column: 1/13;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
