<template>
  <div class="msg" :class="sender ? 'right-msg' : 'left-msg'">
    <div class="msg-bubble">
      <div class="msg-info">
        <div class="msg-info-name">
          <span v-if="!sender">{{ name }}</span>
        </div>
        <div class="msg-info-time">{{ formattedTime }}</div>
      </div>
      <div class="msg-text">
        <slot />
      </div>
      <div v-if="sender" class="trash-can-btn">
        <a @click="deleteMyChatMessage(id)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isToday } from 'date-fns';

export default {
  props: {
    name: { type: String, default: '' },
    time: { type: String, default: '' },
    sender: { type: Boolean, default: false },
    id: { type: Number, default: 0 }
  },
  computed: {
    formattedTime() {
      let date = new Date(this.time);
      let formatText = 'yy-MM-dd HH:mm';
      if (isToday(date)) {
        formatText = 'HH:mm';
      }

      return format(date, formatText);
    }
  },
  methods: {
    deleteMyChatMessage(chat_id) {
      this.$emit('deletemessage', chat_id);
    }
  }
};
</script>

<style lang="scss" scoped>
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: #ececec;
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-size: 0.7em;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.7em;
}
.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
  background: #ececec;
  text-align: left;
}
.trash-can-btn {
  margin-bottom: -15px;
  margin-left: 0px;
}
.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble:hover .trash-can-btn {
  visibility: visible;
}
.right-msg .msg-bubble .trash-can-btn {
  visibility: hidden;
}
.fa-trash:hover {
  color: #cc0033;
}
.right-msg .msg-bubble {
  background: #579ffb;
  color: #fff;
  border-bottom-right-radius: 0;
  text-align: right;
}
.msg-text {
  white-space: pre-line;
  font-size: 0.8em;
}
</style>
