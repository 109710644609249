<template>
  <form class="msger-inputarea" @submit.prevent="sendMyMessage">
    <textarea ref="messageInput" v-model="myChatMessage" class="msger-input" placeholder="Textmeddelande" maxlength="1000" rows="1"></textarea>
    <button type="submit" class="msger-send-btn">Skicka</button>
  </form>
</template>

<script>
import { socket } from '@/services/socket';

export default {
  name: 'MessageInput',
  data() {
    return {
      myChatMessage: '',
      currentUser: null,
      loggedInUserId: 0,
      loggedInUserFullName: ''
    };
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    this.loggedInUserId = this.currentUser.user_id;
    this.loggedInUserFullName = this.currentUser.full_name;
  },
  methods: {
    async sendMyMessage() {
      if (this.myChatMessage !== '') {
        try {
          let message = {
            user_id: this.loggedInUserId,
            user_full_name: this.loggedInUserFullName,
            chat_message: this.myChatMessage
          };
          socket.emit('globalMessage', { message });
          this.myChatMessage = '';
          this.$refs.messageInput.focus();
        } catch (error) {
          //   console.log(error);
          // TODO : FIX ERROR LOG
        }
      }
    }
  }
};
</script>

<style scoped>
.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: 2px solid #ddd;
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  height: 50px;
}
.msger-input {
  flex: 1;
  background: #ddd;
  font-size: 0.9em;
  resize: none;
}
.msger-send-btn {
  margin-left: 10px;
  background: rgb(0, 196, 65);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
  font-size: 0.85em;
}
.msger-send-btn:hover {
  background: rgb(0, 180, 50);
}
</style>
