<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header id="modalTitle" class="modal-header">
          <slot name="header"> This is the default tile! {{ force }} </slot>
        </header>

        <section id="modalDescription" class="modal-body">
          <div class="modal-body-text">Skicka en e-post med länk till order.<br />Ordernr: {{ order.order_number }}</div>
          <div class="modal-email-info">
            <div v-if="order.email_address.length > 0">
              <div class="modal-email-info-text">E-post skickat till: {{ order.email_address }}</div>
              <div class="modal-email-info-text">Skickades: {{ order.email_sent_date }}</div>
            </div>
            <div v-else>
              <div class="modal-email-info-text">Ingen e-post är tidigare skickad för denna order.</div>
            </div>
          </div>

          <label class="modal-label">E-postadress</label>
          <input ref="emailaddress" v-model="email_address" class="pg-input" type="email" required @change="clearErrorMessage" />
          <div v-if="errorMessage" class="errorMessageText">
            {{ errorMessage }}
          </div>
        </section>
        <footer class="modal-footer">
          <slot name="footer"> </slot>
          <button type="button" class="btn-primary btn-action" aria-label="send" @click="sendEmail">Skicka</button>
          <button type="button" class="btn-danger btn-action" aria-label="close" @click="close">Avbryt</button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import { validateEmail } from '@/services/validationHelper';

export default {
  name: 'PGSendEmailModal',
  props: {
    order: {
      type: Object
    },
    force: {
      type: Number
    }
  },
  data() {
    return {
      isDisabled: true,
      errorMessage: '',
      amount: 0,
      email_address: ''
    };
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    });

    this.$refs.emailaddress.focus();
  },
  // updated() {
  //   this.$refs.emailaddress.focus();
  // },
  methods: {
    close() {
      this.email_address = '';
      this.amount = 0;
      this.errorMessage = '';
      this.$emit('close');
    },
    clearErrorMessage() {
      this.errorMessage = '';
    },
    sendEmail() {
      this.email_address = this.email_address.trim();
      if (!validateEmail(this.email_address)) {
        this.errorMessage = 'Ogiltig e-postadress';
        this.isDisabled = true;
      } else {
        this.errorMessage = '';
        this.isDisabled = false;
        this.amount = 0;

        let emailAdress = this.email_address;
        this.email_address = '';
        this.$emit('send', emailAdress);
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  //display: flex;
  //  justify-content: center;
  //  align-items: center;
  width: 100%;
  height: 100%;
}

.modal2 {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.modal {
  width: 400px;
  padding: 20px;
  margin: 100px auto;
  background: white;
  border-radius: 10px;

  h1 {
    color: #03cfb4;
    border: none;
    padding: 0;
  }

  p {
    font-style: normal;
  }
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #fff;
  font-size: 1.3rem;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
}

.modal-header.articlein {
  background: #3db64a;
}
.modal-header.articleout {
  background: #ff7300;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-email-info {
  margin: 15px 0;
}
.modal-email-info-text {
  margin: 0px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 0;
  color: $gray-700;
}
.modal-body-text {
  margin: 0px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}

.modal-label {
  margin: 5px 0;
  display: block;
  text-align: left;
  line-height: 1.5;
  font-size: 0.7rem;
  font-weight: 600;
  margin-bottom: 0;
}
.btn-action {
  min-width: 100px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
</style>
