<template>
  <div id="chat" class="grid">
    <div class="chat-container">
      <div class="chat-container2">
        <section class="msger">
          <div class="msger-header">
            <div class="msger-header-title"><i class="fas fa-comment-alt"></i> Chatt</div>
            <div class="msger-header-options">
              <button id="toggleAutoScroll" class="btn-primary btn-primary-active" onmousedown="event.preventDefault()" title="Auto scroll på/av" @click.prevent="toggleAutoScroll">
                <i class="fas fa-arrow-down" aria-hidden="true"></i>
              </button>
              <button class="btn-primary last-btn" onmousedown="event.preventDefault()" title="Ändra storlek på chatten" @click.prevent="changeSize">
                <i class="fas fa-expand" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <ChatLog :auto-scroll="autoScroll"></ChatLog>
          <MessageInput></MessageInput>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ChatLog from '@/components/chat/ChatLog.vue';
import MessageInput from '@/components/chat/MessageInput';

export default {
  name: 'ChatContainer',
  components: {
    ChatLog,
    MessageInput
  },
  data() {
    return {
      reloading: false,
      loading: false,
      errorMessage: '',
      myChatMessage: '',
      autoScroll: true
    };
  },
  computed: {},

  mounted() {},
  created() {},
  updated() {},
  beforeUpdate() {},

  destroyed() {},
  methods: {
    toggleAutoScroll() {
      let ElementCssClass = document.getElementById('toggleAutoScroll');
      ElementCssClass.classList.toggle('btn-primary-active');
      this.autoScroll = !this.autoScroll;
    },
    changeSize() {
      let ElementCssClass = document.getElementById('column2');
      ElementCssClass.classList.toggle('column-2-bigger');
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-container {
  grid-column: 1/13;
}
.chat-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;
  padding-bottom: 0px;
  overflow: hidden;
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  // margin: 25px 10px;
  height: calc(100% - 20px);
  border: 2px solid #ddd;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
}
.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  background: #eee;
  color: #666;
}
.msger-header-options .last-btn {
  margin-left: 10px;
}
</style>
