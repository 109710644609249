<template>
  <main ref="chatLogContainer" class="msger-chat">
    <div v-if="loading && errorMessage === ''" class="pg-center">
      <Spinner></Spinner>
    </div>
    <MessageBubble
      v-for="message in myHistoryChatMessages"
      :id="message.chat_id"
      :key="message.chat_id"
      :name="message.user_full_name"
      :time="message.create_date"
      :sender="message.user_id === loggedInUserId"
      @deletemessage="deleteMessage">
      {{ message.chat_message }}
    </MessageBubble>
    <MessageBubble
      v-for="message in myChatMessages"
      :id="message.chat_id"
      :key="message.chat_id"
      :name="message.user_full_name"
      :time="message.create_date"
      :sender="message.user_id === loggedInUserId"
      @deletemessage="deleteMessage">
      {{ message.chat_message }}
    </MessageBubble>
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';
import Spinner from '@/components/utils/Spinner';
import MessageBubble from '@/components/chat/MessageBubble';
import { socket } from '@/services/socket';
/**
 * Auto scrolls the chat log to the bottom when a new message is received
 */
function scrollBottom() {
  this.$el.scrollTo(0, this.$el.scrollHeight);
}
function scrollHistory() {
  let newScrollTop = this.$el.scrollHeight - this.scrollHeightDefault - this.limit * 2;
  /*
console.log(`SCROLL HEIGHT DEFAULT ${this.scrollHeightDefault}`);
console.log(`SCROLL HEIGHT  ${this.$el.scrollHeight}`);
console.log(`newScrollTop  ${newScrollTop}`);
console.log(`offset  ${this.offset}`);
console.log(`limit  ${this.limit}`);
    */
  this.$el.scrollTo(0, newScrollTop);
  this.scrollHeightDefault = this.$el.scrollHeight;
}
export default {
  name: 'ChatLog',
  components: {
    MessageBubble,
    Spinner
  },
  props: {
    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      reloading: false,
      loading: false,
      errorMessage: '',
      myChatMessages: [],
      myHistoryChatMessages: [],
      limit: 20,
      offset: 0,
      messagesCount: 0,
      currentUser: null,
      loggedInUserId: 0,
      loggedInUserFullName: '',
      scrollHeightDefault: 0
    };
  },
  computed: {},

  async mounted() {
    this.currentUser = this.$store.getters.currentUser;
    this.loggedInUserId = this.currentUser.user_id;
    this.loggedInUserFullName = this.currentUser.full_name;
    await this.getMyHistoryChatMessages();
    this.$nextTick(scrollBottom);

    this.$el.addEventListener(
      'scroll',
      async (event) => {
        if (event.target.scrollTop === 0) {
          if (!this.reloading && this.myHistoryChatMessages.length < this.messagesCount) {
            this.loading = true;
            this.offset = this.offset + this.limit;
            await this.getMyHistoryChatMessages();
            this.loading = false;
          }
        }
      },
      false
    );
  },
  created() {
    socket.on('chatMessage', (data) => {
      this.myChatMessages.push(data);
    });
  },
  updated() {},
  beforeUpdate() {},
  destroyed() {},
  methods: {
    ...mapActions({
      fetchChatMessages: 'chat/fetchChatMessages',
      deleteChatMessage: 'chat/deleteChatMessage'
    }),
    async deleteMessage(chat_id) {
      await this.deleteMyChatMessage(chat_id);
    },
    async getMyHistoryChatMessages() {
      this.loading = true;
      await this.fetchMyHistoryChatMessages();
      this.loading = false;
    },
    async fetchMyHistoryChatMessages() {
      this.errorMessage = '';
      try {
        let data = {
          limit: this.limit,
          offset: this.offset
        };
        let result = await this.fetchChatMessages(data);
        this.myHistoryChatMessages.unshift(...result.chatMessages);
        this.messagesCount = result.messagesCount;
      } catch (error) {
        // console.error(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          } else {
            this.errorMessage = 'Kunde inte hämta meddelanden';
          }
        }
      }
    },
    async deleteMyChatMessage(chat_id) {
      let response = null;
      try {
        let data = {
          chat_id: chat_id
        };
        response = await this.deleteChatMessage(data);
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
      } finally {
        if (response.success == 1) {
          this.myChatMessages = this.myChatMessages.filter(function (item) {
            return item.chat_id !== chat_id;
          });
          this.myHistoryChatMessages = this.myHistoryChatMessages.filter(function (item) {
            return item.chat_id !== chat_id;
          });
        }
        // console.log("We do cleanup here");
      }
    }
  },
  watch: {
    myHistoryChatMessages: function () {
      this.$nextTick(scrollHistory);
    },
    myChatMessages: function () {
      if (this.autoScroll) {
        this.$nextTick(scrollBottom);
      }
    }
  }
};
</script>

<style>
.msger-chat {
  flex: 1;
  overflow-y: auto;
  overflow: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
</style>
