<template>
  <div class="column-wrapper">
    <div class="column column-1">
      <WorkOrders />
    </div>
    <div id="column2" class="column column-2">
      <ChatContainer />
    </div>
  </div>
</template>

<script>
import WorkOrders from '@/views/workorders/WorkOrders';
import ChatContainer from '@/components/chat/ChatContainer';
export default {
  name: 'WO',
  components: {
    WorkOrders,
    ChatContainer
  },
  methods: {
    toogle() {
      let ElementCssClass = document.getElementById('column2');
      ElementCssClass.classList.toggle('column-2-bigger');
    }
  }
};
</script>

<style lang="scss">
.column-wrapper {
  display: flex;
  flex-direction: row;
}
.column {
  height: 100vh;
}
.column-2 {
  width: 20%;
}
.column-1 {
  flex: 1; /* adjust automatically */
}
.column-2-bigger {
  width: 30%;
}
</style>
